import { useState } from 'react';
import Link from 'next/link';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
// Components
import { AlertBody, ModalBody } from '@/common/index';
import { brand } from '@/brand/brand';
// Utils
import { useAuth } from '@/hooks/useAuth';
import { useFeatureFlags } from '@/providers/FeatureFlagProvider';
import { getHasValidEmail, getHasValidName, errorMessages } from '@/utils/validation';
import { routes } from '@/routes/routes';
import { engageWaitlist } from '@/resources/membership-service.resource.js';
import { config } from 'config';
// Styles & Assets
import { ArrowRightLong } from '@/assets/icons/iconComponents/ArrowRightLong';
import styles from './join-waitlist.module.css';

const INVITE_ONLY = ' Ask the person who referred you for an invitation link.';

const initFormState = {
  firstName: { value: '', error: false, message: '' },
  lastName: { value: '', error: false, message: '' },
  email: { value: '', error: false, message: '' },
  phone: { value: '', error: false, message: '' },
  agreement: { value: false },
};

const initialErrorState = {
  error: false,
  header: '',
  message: '',
};

export default function JoinWaitlist() {
  // Feature flag ShowWaitlistForm is used to toggle whether the page will have a user form to be saved in Engage. This information is NOT saved in core databases.

  // The wait list form will submit user data to engage via an api call. Brand and env specific Engage api key secret is required in the backend connection to Engage to work properly.

  const { fetcher } = useAuth();
  const { featureFlags } = useFeatureFlags();
  const [formState, setFormState] = useState(initFormState);
  const [error, setError] = useState(initialErrorState);
  const [showSuccess, setShowSuccess] = useState(false);
  const marketingSite = config.marketingFunnel.signup;

  // For a brand with NO env var NEXT_PUBLIC_MARKETING_FUNNEL -> if brand would like to allow users to see links to social sites, populate these in the brand.js file.
  const activeSocials = Object.values(brand.socialMediaSites).filter(
    (site) => site.isActive
  );

  const handleInputChange = ({ target: { value, id, checked } }) => {
    setError(initialErrorState);
    const setValue = id === 'agreement' ? checked : value;

    setFormState((previousState) => ({
      ...previousState,
      [id]: {
        ...previousState[id],
        value: setValue,
        error: false,
        message: '',
      },
    }));
  };

  const handlePhone = (value) => {
    setFormState((prevState) => ({
      ...prevState,
      phone: { ...prevState.phone, value },
    }));
  };

  const handlePhoneValidation = (value) => {
    const isValid = !value || matchIsValidTel(value);
    return setFormState((previousState) => ({
      ...previousState,
      phone: {
        ...previousState.phone,
        error: !isValid,
        message: isValid ? '' : errorMessages.phone,
      },
    }));
  };

  const handleValidation = async ({ target: { id, value } }) => {
    value = value.trim();
    switch (id) {
      case 'firstName':
      case 'lastName': {
        let message = '';
        const hasLength = value && value.length;
        if (!hasLength) {
          return setFormState((previousState) => ({
            ...previousState,
            [id]: {
              value,
              error: !hasValidName || isTooLong,
              message: 'This field is required.',
            },
          }));
        }
        const hasValidName = getHasValidName({ name: value });
        const isTooLong = value.length > 50;
        if (!hasValidName) {
          message = errorMessages.name;
        } else if (isTooLong) {
          message = 'Please enter a name less than 50 characters.';
        }
        return setFormState((previousState) => ({
          ...previousState,
          [id]: {
            value,
            error: !hasValidName || isTooLong,
            message: message,
          },
        }));
      }
      case 'email': {
        const validEmail = getHasValidEmail({ email: value });

        if (!validEmail) {
          return setFormState((prev) => ({
            ...prev,
            [id]: {
              value,
              error: true,
              message: 'Enter a valid email (sally@example.com)',
            },
          }));
        }
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetcher(
      engageWaitlist({
        firstName: formState.firstName.value,
        lastName: formState.lastName.value,
        email: formState.email.value,
        phone: formState.phone.value,
        tags: ['waitlist-node'],
      })
    )
      .then((data) => {
        if (data.duplicateRequest) {
          setError({
            error: true,
            header: 'System Error.',
            message:
              'The email address you’re trying to add is already on the list. Please try again with a different email.',
          });
        } else {
          setShowSuccess(true);
        }
      })
      .catch(() => {
        setError({
          error: true,
          header: 'System Error.',
          message:
            'You are unable to join the waitlist at this time. Please refresh the page or try again later.',
        });
      });
  };

  const isDisabled = () => {
    const hasErrors =
      Object.values(formState).filter((state) => state.error === true).length > 0;
    if (
      hasErrors ||
      !formState.firstName.value ||
      !formState.lastName.value ||
      !formState.email.value ||
      !formState.agreement.value
    ) {
      return true;
    }
    return false;
  };

  const closeModal = () => setShowSuccess(false);

  return (
    <Paper
      elevation={1}
      sx={{
        width: '448px',
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        '@media (max-width: 520px)': {
          width: '100%',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '24px',
        }}
      >
        <img src={brand.logo} className={styles.logo} alt={`${brand.brandName} Logo`} />
        <Typography variant="h2">Welcome to {brand.brandName}</Typography>
      </Box>
      {error.error ? (
        <Alert variant="outlined" severity="error">
          <AlertBody header={error.header} body={error.message} />
        </Alert>
      ) : null}
      <Box sx={{ paddingTop: '8px' }}>
        <Typography variant="body1">
          New {brand.brandName} accounts are invite-only at this time.
          <span>
            {marketingSite
              ? `Join the waitlist
          to connect with the ${brand.brandName} community to learn more. We will notify
          you when a new invite is available.`
              : INVITE_ONLY}
          </span>
        </Typography>
      </Box>
      {featureFlags.ShowWaitlistForm ? (
        <form onSubmit={handleSubmit} className={styles.form}>
          <TextField
            id="firstName"
            type="text"
            fullWidth
            size="small"
            error={formState.firstName.error}
            helperText={formState.firstName.error && formState.firstName.message}
            label="First Name*"
            onChange={handleInputChange}
            onBlur={handleValidation}
            value={formState.firstName.value}
            data-core-qa="waitInputFirst"
            placeholder="Enter your first name"
          />
          <TextField
            id="lastName"
            type="text"
            fullWidth
            size="small"
            error={formState.lastName.error}
            helperText={formState.lastName.error && formState.lastName.message}
            label="Last Name*"
            onChange={handleInputChange}
            onBlur={handleValidation}
            value={formState.lastName.value}
            data-core-qa="waitInputLast"
            placeholder="Enter your last name"
          />
          <TextField
            id="email"
            type="text"
            fullWidth
            size="small"
            error={formState.email.error}
            helperText={formState.email.error && formState.email.message}
            label="Email Address*"
            onChange={handleInputChange}
            onBlur={handleValidation}
            value={formState.email.value || undefined}
            data-core-qa="waitInputEmail"
            placeholder="email@example.com"
          />
          <Box>
            <MuiTelInput
              label="Phone Number (optional)"
              data-core-qa="waitInputPhone"
              defaultCountry="US"
              country="US"
              id="phone"
              error={formState?.phone?.error}
              onChange={handlePhone}
              value={formState?.phone?.value ?? ''}
              onBlur={() => handlePhoneValidation(formState?.phone?.value)}
              fullWidth
              size="small"
            />
            {formState?.phone?.error ? (
              <Box sx={{ marginLeft: '16px' }}>
                <Typography
                  variant="body3"
                  color="error"
                  data-core-qa="profilePhoneErrorText"
                >
                  {formState?.phone?.message}
                </Typography>
              </Box>
            ) : null}
          </Box>
          <FormControlLabel
            label={`I agree to email marketing communications from ${brand.brandName} regarding events and news.`}
            labelPlacement="end"
            control={
              <Checkbox
                id="agreement"
                data-core-qa="signupIsAdultCheckbox"
                checked={formState.agreement.value || false}
                name="agreement"
                size="small"
                onChange={handleInputChange}
              />
            }
          />
          <Button
            data-core-qa="waitButton"
            type="submit"
            size="large"
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            disabled={isDisabled()}
          >
            Join Waitlist
          </Button>
        </form>
      ) : null}
      {!marketingSite && activeSocials.length ? (
        <>
          <Box sx={{ marginTop: '24px' }}>
            <Typography variant="body2">
              Want the latest updates on {brand.brandName} and how to receive an
              invite-only referral link? Join us on the following social platforms.
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: '24px',
              display: 'flex',
              gap: '16px',
              justifyContent: activeSocials.length > 4 ? 'space-between' : 'flex-start',
              flexWrap: 'wrap',
            }}
          >
            {activeSocials.map((social, i) => (
              <IconButton
                key={i}
                onClick={() => window.open(social.url)}
                sx={{
                  width: '32px',
                  height: '32px',
                  backgroundColor: social.buttonBackgroundColor,
                  '&:hover': {
                    backgroundColor: `${social.buttonBackgroundColor}aa`,
                  },
                }}
              >
                <img src={social.icon} alt="social icon" />
              </IconButton>
            ))}
          </Box>
        </>
      ) : null}
      <Box sx={{ marginTop: '24px' }}>
        <Typography variant="body2">
          Already have an account?
          <Box component="span">
            <Link href={routes.login.path} data-core-qa="signupLoginHereLink">
              <a className={styles.link}>
                Login here <ArrowRightLong className={styles.arrowIcon} />
              </a>
            </Link>
          </Box>
        </Typography>
      </Box>
      <Dialog open={showSuccess} onClose={closeModal}>
        <ModalBody
          header={`You're on the Waitlist!`}
          body={`Check your inbox for the latest updates, and to learn more about exciting things happening at ${brand.brandName}.`}
          buttonPrimaryText="Close"
          buttonPrimaryOnClick={closeModal}
          showClose
          handleClose={closeModal}
        />
      </Dialog>
    </Paper>
  );
}
