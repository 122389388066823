const NAME_ERROR = 'Improperly Formatted Name';
const NAME_ERROR_ALERT =
  'Name must be at least 2 characters long and can not contain any symbols.';
const EMAIL_ERROR = 'Enter a valid email (sally@example.com)';
const PASSWORD_ERROR = `Password does not meet the character requirement`;
const CONFIRM_ERROR = `Password does not match`;

export const errorMessages = {
  name: NAME_ERROR,
  nameAlert: NAME_ERROR_ALERT,
  email: EMAIL_ERROR,
  password: PASSWORD_ERROR,
  confirm: CONFIRM_ERROR,
  length: 'Please enter a name less than 50 characters.',
  emailExists: {
    header: 'An account with this email address already exists.',
    message: 'Please login using this email address or reset the password.',
    resetLink: true,
  },
  systemError: {
    header: 'Unexpected Error.',
    message: 'Something has gone wrong, please try again.',
  },
};

export const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  isAdult: false,
  newsletter: false,
  errors: {},
  canPurchaseNodes: false,
};

export const initialErrorState = {
  header: '',
  message: '',
};
