import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Box } from '@mui/material';
// Components
import SignUpForm from './components/SignUpForm/SignUpForm';
import JoinWaitlist from './components/JoinWaitlist/JoinWaitlist';
import { LoadingSpinner } from '@/common/index';
// Utils
import { useFeatureFlags } from '@/providers/FeatureFlagProvider';
import { useCookies } from '@/hooks/useCookies';
import { config } from 'config';
// Assets & Styles
import styles from './signup.module.css';

export default function Signup() {
  const router = useRouter();
  const { featureFlags, isLoading: isFeatureFlagsLoading } = useFeatureFlags();
  const { handleGetReferral, handleSetReferral } = useCookies();
  const [isLoading, setIsLoading] = useState(true);
  const [canSignUp, setCanSignUp] = useState(true);

  const { signup } = config.marketingFunnel; // ! ADD waitlist TO THIS OBJECT LINE TO ENABLE WAITLIST and UNCOMMENT LINE 50

  const redirectToExternalPage = (url) => (window.location.href = url);

  useEffect(() => {
    if (router.isReady) {
      // esp (enable shopping products) is a query param used to set canPurchaseNodes in the member table. This boolean can override the isAvailableInApp product flag allowing special invited members to purchase nodes when other members can not.
      const canPurchaseNodes =
        router.query.esp !== undefined && router.query.esp === 'on';

      localStorage.setItem('esp', canPurchaseNodes);
    }
  }, [router.isReady]);

  useEffect(() => {
    if (router.isReady) {
      // esp (enable shopping products) is a query param used to set canPurchaseNodes in the member table. This boolean can override the isAvailableInApp product flag allowing special invited members to purchase nodes when other members can not.
      const canPurchaseNodes =
        router.query.esp !== undefined && router.query.esp === 'on';

      localStorage.setItem('esp', canPurchaseNodes);
    }
  }, [router.isReady]);

  useEffect(() => {
    if (router.isReady) {
      // Save sponsor id in case they sign up later.
      handleSetReferral();
    }

    const sponsor = handleGetReferral(); // get sponsor id from cookie || local storage

    // On loading the signup page check to see if referral sponsor id is required.
    if (featureFlags['Membership.Signup.RestrictToReferral']) {
      // Referral required, and sponsor id exists:
      if (sponsor) {
        // Allow signup for this user.
        setCanSignUp(true);

        // If redirect flag is true and there is a redirect url in env vars NEXT_PUBLIC_MARKETING_FUNNEL, send to new url with sponsor id query param included.
        if (featureFlags.RedirectSignupToExternal && signup?.length > 0) {
          redirectToExternalPage(`${signup}?sponsor=${sponsor}`);
        }
        // If redirect flag is false or the env var isn't set for NEXT_PUBLIC_MARKETING_FUNNEL, core sign up form will be shown to the user SignUpForm.js.
      } else {
        // if (waitlist) return redirectToExternalPage(waitlist);   // ! UNCOMMENT THIS LINE TO ENABLE ENGAGE WAITLIST REDIRECT

        // No sponsor -> user can not sign up yet -> show JoinWaitlist.js
        setCanSignUp(false);
        setIsLoading(false);
      }
    } else {
      // No sponsor is required for sign up -> show SignupForm.js
      setIsLoading(false);
    }
  }, [router.isReady, featureFlags]);

  if (isLoading) {
    return (
      <section className={styles.formSection}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '90px',
          }}
        >
          <LoadingSpinner type="dark" />
        </Box>
      </section>
    );
  }

  return (
    <Box
      component="section"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        background: `url(${config.brand.loginImage})`,
        overflow: 'auto',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {!isFeatureFlagsLoading && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '488px',
            // height: 'fit-content',
            margin: '50px 0',
          }}
        >
          {canSignUp ? <SignUpForm /> : <JoinWaitlist />}
        </Box>
      )}
    </Box>
  );
}

Signup.strictlyPublicRoute = true;
