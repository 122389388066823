import { useReducer, useState } from 'react';
import * as auth from '@nerdcoresdk/nerd-core-auth';
import Link from 'next/link';
import { useRouter } from 'next/router';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
// Components
import {
  AlertBody,
  LoadingSpinner,
  PasswordInputIcon,
  PasswordValidation,
} from '@/common/index';
// Hooks
import { useCookies } from '@/hooks/useCookies';
import { routes } from '@/routes/routes';
// Utils
import { brand } from '@/brand/brand';
import {
  getHasValidEmail,
  getHasValidName,
  getIsPasswordValid,
} from '@/utils/validation';
import { config } from 'config';
// Helpers
import { errorMessages, initialErrorState, initialState } from '../constants';
// Styles
import { ArrowRightLong } from '@/assets/icons/iconComponents/ArrowRightLong';
import arrow from '@/icons/fontawesome/arrow-right-solid.svg';
import styles from './signUpForm.module.css';

function reducer(state, action) {
  switch (action.type) {
    case 'updateField':
      return { ...state, [action.field]: action.value };
    case 'setChecked':
      return { ...state, [action.field]: !state[action.field] };
    case 'setError':
      return { ...state, errors: { ...state.errors, [action.field]: action.message } };
    case 'clearError': {
      const errorsCopy = state.errors;
      delete errorsCopy[action.field];
      return { ...state, errors: errorsCopy };
    }
    default:
      return state;
  }
}

export default function SignUpForm() {
  const router = useRouter();
  const { signup } = auth.getInstance();
  const { handleGetReferral } = useCookies();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showAlert, setShowAlert] = useState(initialErrorState);
  const [isLoading, setIsLoading] = useState(false);
  const [recaptcha, setRecaptcha] = useState(null);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const dispatchError = (field, message) =>
    dispatch({ type: 'setError', field, message });

  const clearError = (field) => {
    dispatch({ type: 'clearError', field });
    // Leave invalid name Alert up until valid
    if (state.errors?.firstName || state.errors?.lastName) return;
    setShowAlert(initialErrorState);
  };

  const handleNameError = (field) => {
    dispatchError(field, errorMessages.name);
    setShowAlert({ header: errorMessages.name, message: errorMessages.nameAlert });
  };

  const validateFieldOnBlur = {
    firstName: (name) => {
      name = name.trim();
      if (name.length > 50) {
        return dispatchError('firstName', errorMessages.length);
      }
      getHasValidName({ name }) ? clearError('firstName') : handleNameError('firstName');
    },
    lastName: (name) => {
      name = name.trim();
      if (name.length > 50) {
        return dispatchError('lastName', errorMessages.length);
      }
      getHasValidName({ name }) ? clearError('lastName') : handleNameError('lastName');
    },
    email: (email) =>
      getHasValidEmail({ email })
        ? clearError('email')
        : dispatchError('email', errorMessages.email),
    password: (password) =>
      getIsPasswordValid({ password })
        ? clearError('password')
        : dispatchError('password', errorMessages.password),
    confirmPassword: () =>
      state.password === state.confirmPassword
        ? clearError('confirmPassword')
        : dispatchError('confirmPassword', errorMessages.confirm),
  };

  const confirmPasswordStatus =
    state.confirmPassword && state.confirmPassword === state.password
      ? { status: 'success', message: 'Password matches' }
      : state.confirmPassword && state.errors?.confirmPassword
        ? { status: 'error', message: errorMessages.confirm }
        : { status: 'default', message: '' };

  const isFormValid = () => {
    const { firstName, lastName, email, password, confirmPassword, isAdult } = state;

    const hasValidName =
      getHasValidName({ name: firstName }) && getHasValidName({ name: lastName });
    const hasValidEmail = getHasValidEmail({ email });
    const isPasswordValid = getIsPasswordValid({ password });
    const passwordsMatch = password === confirmPassword;

    if (
      !hasValidName ||
      !hasValidEmail ||
      !isPasswordValid ||
      !passwordsMatch ||
      !isAdult ||
      !recaptcha
    ) {
      return false;
    }

    return true;
  };

  const isDisabled =
    Object.keys(state.errors).length > 0 || !isFormValid() || !!showAlert.header;

  const handleFocus = (e) => {
    const { name, value } = e.target;
    if (!value && !state.errors[name]) return;
    dispatch({ type: 'clearError', field: name });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: 'updateField', field: name, value });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (!value) return;
    validateFieldOnBlur[name](value);
  };

  const handleChecked = (e) => {
    const { name } = e.target;
    dispatch({ type: 'setChecked', field: name });
  };

  const handleRecaptcha = (token) => setRecaptcha(token);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const sponsor = handleGetReferral();
    const canPurchaseNodes = localStorage.getItem('esp') === 'true';
    const brandAmbassadorId = sessionStorage.getItem('brandAmbassadorId');

    const baseUrl = `${window.location.origin}/email-confirmation`;
    const confirmationLinkUrl = brandAmbassadorId
      ? `${baseUrl}?esp=on&redirect=/myambassador/${brandAmbassadorId}`
      : baseUrl;

    const formData = {
      ...state,
      sponsor,
      firstName: state.firstName.trim(),
      lastName: state.lastName.trim(),
      confirmationLinkUrl,
      name: `${state.firstName} ${state.lastName}`,
      canPurchaseNodes: canPurchaseNodes,
    };
    // Remove unnecessary data
    delete formData.confirmPassword;
    delete formData.isAdult;

    signup(formData)
      .then(() => {
        canPurchaseNodes && localStorage.removeItem('esp');
        routeOnSuccess();
      })
      .catch((err) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (err.error?.message && err.error.message.includes('already-in-use')) {
          setShowAlert({ ...errorMessages.emailExists });
        } else setShowAlert({ ...errorMessages.systemError });
      })
      .finally(() => setIsLoading(false));
  };

  const routeOnSuccess = () => {
    const encodedEmail = encodeURIComponent(state.email);
    router.push({
      pathname: routes.emailCheck.path,
      query: { email: encodedEmail },
    });
  };

  return (
    <Paper
      elevation={1}
      sx={{
        width: '448px',
        margin: 'auto',
      }}
    >
      <div className={styles.formContainer}>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formTitle}>
            <img
              src={brand.authenticationLogo}
              className={styles.logo}
              alt={`${brand.brandName} Logo`}
            />
            <Typography variant="h2" data-core-qa="signupCreateAccountHeader">
              Create Your Account
            </Typography>
            <span className={styles.subHeader} data-core-qa="signupAlreadyHaveAccount">
              <Typography variant="body1dark">Already have an account?</Typography>
              <Link href={routes.login.path} data-core-qa="signupLoginHereLink">
                <a>
                  Login here{' '}
                  <img src={arrow.src} alt="arrow" className={styles.arrowIcon} />
                </a>
              </Link>
            </span>
          </div>
          {showAlert?.message ? (
            <div className={styles.errorMessageContainer}>
              <Alert severity="error" variant="outlined">
                <AlertBody header={showAlert.header} body={showAlert.message}>
                  {showAlert?.resetLink && (
                    <Link className={styles.resetLink} href="/forgot-password">
                      <a
                        className={styles.resetLink}
                        data-core-qa="signupResetPasswordLink"
                      >
                        {' '}
                        Reset Password <ArrowRightLong className={styles.arrowIconErr} />
                      </a>
                    </Link>
                  )}
                </AlertBody>
              </Alert>
            </div>
          ) : null}
          <TextField
            className={styles.formInput}
            data-core-qa="signupFirstNameInput"
            size="small"
            name="firstName"
            label="First Name"
            placeholder="First Name"
            value={state.firstName}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            error={state.errors?.firstName}
            helperText={state.errors?.firstName || ''}
            fullWidth
          />
          <TextField
            className={styles.formInput}
            data-core-qa="signupLastNameInput"
            size="small"
            name="lastName"
            label="Last Name"
            placeholder="Last Name"
            value={state.lastName}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            error={state.errors?.lastName}
            helperText={state.errors?.lastName || ''}
            fullWidth
          />
          <TextField
            className={styles.formInput}
            data-core-qa="signupEmailInput"
            autoComplete="new-email"
            size="small"
            name="email"
            type="email"
            label="Email Address"
            placeholder="email@example.com"
            value={state.email || undefined}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            error={state.errors?.email}
            helperText={state.errors?.email || ''}
            fullWidth
          />
          <TextField
            className={styles.formInput}
            data-core-qa="signupPasswordInput"
            size="small"
            autoComplete="new-password"
            name="password"
            label="Create Password"
            placeholder="Password"
            type={showPassword ? 'text' : 'password'}
            value={state.password || undefined}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            error={state.errors?.password}
            helperText={state.errors?.password || ''}
            fullWidth
            InputProps={{
              endAdornment: (
                <PasswordInputIcon state={showPassword} setState={setShowPassword} />
              ),
            }}
          />
          {state.password && <PasswordValidation password={state.password} />}
          <TextField
            className={styles.formInput}
            data-core-qa="signupConfirmPasswordInput"
            size="small"
            autoComplete="new-password"
            name="confirmPassword"
            label="Confirm Password"
            placeholder="Password"
            type={showConfirmPassword ? 'text' : 'password'}
            value={state.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            error={confirmPasswordStatus.status === 'error'}
            helperText={confirmPasswordStatus.message}
            fullWidth
            InputProps={{
              endAdornment: (
                <PasswordInputIcon
                  state={showConfirmPassword}
                  setState={setShowConfirmPassword}
                />
              ),
            }}
          />
          <FormControlLabel
            label="I am at least 18 years old"
            labelPlacement="end"
            control={
              <Checkbox
                data-core-qa="signupIsAdultCheckbox"
                checked={state.isAdult}
                name="isAdult"
                size="small"
                onChange={handleChecked}
              />
            }
          />
          <FormControlLabel
            label="Subscribe to our newsletter"
            labelPlacement="end"
            control={
              <Checkbox
                data-core-qa="signupNewsletterCheckbox"
                checked={state.newsletter}
                name="newsletter"
                size="small"
                onChange={handleChecked}
              />
            }
          />
          <ReCAPTCHA
            sitekey={config.firebase.recaptchaKey}
            onChange={handleRecaptcha}
            style={{
              padding: '6px 0 0 0',
            }}
          />
          <div className={styles.button}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              type="submit"
              data-core-qa="signupFormSubmitButton"
              disabled={isDisabled}
            >
              {isLoading ? <LoadingSpinner /> : 'Get Started'}
            </Button>
          </div>
        </form>
      </div>
    </Paper>
  );
}
